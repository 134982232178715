import * as actions from "../actions/actionTypes";

const initialState = {
  StudentDashBoardData: null,
  ViewAllAssignmentData: null,
};
export const StudentdashboardReducer = (state = initialState, action) => {
  console.log("action",action)
  switch (action.type) {
    case actions.GET_STUDENT_DASHBOARD_SUCCESS:
      console.log("action1",action)
      return { ...state, StudentDashBoardData: action.payload };
      case actions.GET_View_All_Assignments_SUCCESS:
      console.log("action1",action)
      return { ...state, ViewAllAssignmentData: action.payload };
    default:
      return state;
  }
};
