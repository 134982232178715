export const url = {
  content: {
    getItems: `/v2/content/get_items`,
    getUnits: `/v2/content/get_units`,
    updateDefaultFilter: `/v2/content/updateDefaultFilter`,
    getStarSmartAndRcTests: `/v2/content/getStarSmart_and_RcTests`,
    getEngineeringDesingItems: `/v2/content/get_engineeringDesing_Items`,
  },
  dashboard: {
    corelationDocumentData: `/v2/Citation/get_citation_data`,
    get_grades_and_standard: `/v2/Citation/get_grades_and_standard`,
    get_item_breakout_list: `/v2/Citation/get_item_breakout_list`,
    studentDashboard: `/v2/studentDashboard/load_student_dashboard`,
    viewAllAssignments: `/v2/studentDashboard/view_all_assignments`
  },
  search: {
    search: `/search`,
  },
  preview: {
    getPreviewItemAccessed: `/PreviewDashboard/get_preview_items_accessed`,
  },
};
