import * as actions from "./actionTypes";

export const getStudentDashboardData = (payload) => ({
  type: actions.GET_STUDENT_DASHBOARD,
  payload: payload,
});

export const getStudentDashboardDataSuccess = (payload) => ({
    type: actions.GET_STUDENT_DASHBOARD_SUCCESS,
    payload: payload,
  });

  export const getViewAllAssignmentsData = (payload) => ({
    type: actions.GET_View_All_Assignments,
    payload: payload,
  });
  
  export const getViewAllAssignmentsDataSuccess = (payload) => ({
      type: actions.GET_View_All_Assignments_SUCCESS,
      payload: payload,
    });