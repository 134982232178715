import { createStore, combineReducers, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";

import { contentLibraryReducer } from "../containers/ContentLibrary/reducers/contentLibrary";
import {StudentdashboardReducer} from "../containers/NewStudentDashboard/reducers/StudentdashboardReducer"
import { authReducer } from "./authReducer";
import { watcherSaga } from "../redux/rootSaga";
import { saveLocalState, loadLocalState } from "../utils/localStorage";
import throttle from "lodash/throttle";

const sagaMiddleware = createSagaMiddleware();
const persistData = loadLocalState();
const reducer = combineReducers({
  contentLibrary: contentLibraryReducer,
  studentDashboard: StudentdashboardReducer,
  auth: authReducer,
});

const store = createStore(
  reducer,
  persistData,
  applyMiddleware(sagaMiddleware)
);

store.subscribe(
  throttle(() => {
    saveLocalState({ contentLibrary: store.getState().contentLibrary });
  }, 600)
);
sagaMiddleware.run(watcherSaga);
export default store;
