import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  getStudentDashboardDataSuccess,
  getViewAllAssignmentsDataSuccess
} from "../actions/studentdashboardActions";
import {
  startSpinner,
  stopSpinner,
  showPopUp,
} from "../../../redux/authActions";
import * as actions from "../actions/actionTypes";
import { doGet, doPost, doGetSearch} from "../../../utils/ajaxHelper";
import { url } from "../../../utils/apiEndpoints";
import { act } from "react-test-renderer";



export function* getStudentDashboard(action) {
  try {
    yield put(startSpinner());
    console.log("action.payload.user_puid",action?.payload)
    const fetchURL = `${url.dashboard.studentDashboard}?user_puid=${action.payload.user_Puid}`;
    const StudentDashboardData = yield call(doGet, fetchURL);
    if ([401, "401"].includes(StudentDashboardData?.status)) {
      yield put(showPopUp(401));
    } else yield put(getStudentDashboardDataSuccess(StudentDashboardData?.data?.data));
  } catch (error) {
    yield put(showPopUp(501));
    yield put(getStudentDashboardDataSuccess(null));
    console.log(error);
  } finally {
    yield put(stopSpinner());
  }
}

export function* getViewAllAssignment(action) {
  try {
    yield put(startSpinner());
    console.log("action.payload.user_puid",action?.payload)
    const fetchURL = `${url.dashboard.viewAllAssignments}?user_puid=${action.payload.user_Puid}&typeId=${action.payload.typeId}`;
    const viewAllAssignmentsData = yield call(doGet, fetchURL);
    if ([401, "401"].includes(viewAllAssignmentsData?.status)) {
      yield put(showPopUp(401));
    } else yield put(getViewAllAssignmentsDataSuccess(viewAllAssignmentsData?.data));
  } catch (error) {
    yield put(showPopUp(501));
    yield put(getViewAllAssignmentsDataSuccess(null));
    console.log(error);
  } finally {
    yield put(stopSpinner());
  }
}

export function* callGetStudentDashboard() {
  yield takeLatest(actions.GET_STUDENT_DASHBOARD, getStudentDashboard);
}
export function* callGetViewAllAssignment() {
  yield takeLatest(actions.GET_View_All_Assignments, getViewAllAssignment);
}


export function* StudentdashboardSaga() {
  yield all([
    callGetStudentDashboard(),
    callGetViewAllAssignment(),
   
  ]);
}
