import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  getUnitDataSuccess,
  setUpdateFilterSuccess,
  getStaarPracticeTestSuccess,
  getItemDataSuccess,
  getCorelationDataSuccess,
  getGradesAndStandardSuccess,
  getItemBreakoutListSuccess,
  getEngineeringDesingItemsSuccess,
  getSearchItem,
  getSearchItemSuccess,
  setPreviewItemDataSuccess,
} from "../actions/contentActions";
import {
  startSpinner,
  stopSpinner,
  showPopUp,
} from "../../../redux/authActions";
import * as actions from "../actions/actionTypes";
import { doGet, doPost, doGetSearch} from "../../../utils/ajaxHelper";
import { url } from "../../../utils/apiEndpoints";
import { act } from "react-test-renderer";
// import { getProductsSuccess } from "../actions/products";
// import { getPRoductsAPI } from "../utils/endpoints";

export function* getUnits(action) {
  try {
    yield put(startSpinner());
    const fetchURL = url.content.getUnits;
    const unitsData = yield call(doPost, fetchURL, action.payload);
    if ([401, "401"].includes(unitsData?.status)) {
      yield put(showPopUp(401));
      yield put(getItemDataSuccess(null));
      yield put(getStaarPracticeTestSuccess(null));
      yield put(getEngineeringDesingItemsSuccess(null));
    } else yield put(getUnitDataSuccess(unitsData?.data));
  } catch (error) {
    yield put(showPopUp(501));
    yield put(getItemDataSuccess(null));
    yield put(getStaarPracticeTestSuccess(null));
    yield put(getEngineeringDesingItemsSuccess(null));
  } finally {
    yield put(stopSpinner());
  }
}
export function* getCorelationData(action) {
  try {
    yield put(startSpinner());
    const fetchURL = `${url.dashboard.corelationDocumentData}?grade_id=${action.payload.grade_id}&standard_name=${action.payload.standard_name}&is_teks=${action.payload.is_teks}`;
    const unitsData = yield call(doGet, fetchURL);
    if ([401, "401"].includes(unitsData?.status)) {
      yield put(showPopUp(401));
    } else yield put(getCorelationDataSuccess(unitsData?.data));
  } catch (error) {
    yield put(showPopUp(501));
    yield put(getCorelationDataSuccess(null));
    console.log(error);
  } finally {
    yield put(stopSpinner());
  }
}
export function* getItemBreakoutList(action) {
  try {
    yield put(startSpinner());
    const fetchURL = `${url.dashboard.get_item_breakout_list}?url=${action.payload.url}`;
    const unitsData = yield call(doPost, fetchURL);
    if ([401, "401"].includes(unitsData?.status)) {
      yield put(showPopUp(401));
    } else yield put(getItemBreakoutListSuccess(unitsData?.data));
  } catch (error) {
    yield put(showPopUp(501));
    console.log(error);
  } finally {
    yield put(stopSpinner());
  }
}
export function* getGradesAndStandard(action) {
  try {
    yield put(startSpinner());
    const fetchURL = url.dashboard.get_grades_and_standard;
    const unitsData = yield call(doPost, fetchURL, action.payload);
    if ([401, "401"].includes(unitsData?.status)) {
      yield put(showPopUp(401));
    } else yield put(getGradesAndStandardSuccess(unitsData?.data));
  } catch (error) {
    yield put(showPopUp(501));
    console.log(error);
  } finally {
    yield put(stopSpinner());
  }
}
export function* setDefaultFilter(action) {
  try {
    yield put(startSpinner());
    const fetchURL = url.content.updateDefaultFilter;
    const unitsData = yield call(doPost, fetchURL, action.payload);
    if ([401, "401"].includes(unitsData?.status)) {
      yield put(showPopUp(401));
    } else yield put(setUpdateFilterSuccess(unitsData?.data?.data));
  } catch (error) {
    yield put(showPopUp(501));
    console.log(error);
  } finally {
    yield put(stopSpinner());
  }
}

export function* getStaarPracticeTest(action) {
  try {
    yield put(startSpinner());
    const fetchURL = url.content.getStarSmartAndRcTests;
    const staarData = yield call(doPost, fetchURL, action.payload);
    if ([401, "401"].includes(staarData?.status)) {
      yield put(showPopUp(401));
      yield put(getItemDataSuccess(null));
      yield put(getStaarPracticeTestSuccess(null));
      yield put(getEngineeringDesingItemsSuccess(null));
    } else yield put(getStaarPracticeTestSuccess(staarData?.data?.data));
  } catch (error) {
    yield put(showPopUp(501));
    yield put(getStaarPracticeTestSuccess(null));
    yield put(getItemDataSuccess(null));
    yield put(getEngineeringDesingItemsSuccess(null));
    console.log(error);
  } finally {
    yield put(stopSpinner());
  }
}

export function* getItemData(action) {
  try {
    yield put(startSpinner());
    const fetchURL = url.content.getItems;
    const itemData = yield call(doPost, fetchURL, action.payload);
    if ([401, "401"].includes(itemData?.status)) {
      yield put(showPopUp(401));
      yield put(getItemDataSuccess(null));
      yield put(getStaarPracticeTestSuccess(null));
      yield put(getEngineeringDesingItemsSuccess(null));
    } else yield put(getItemDataSuccess(itemData?.data?.data));
  } catch (error) {
    yield put(showPopUp(501));
    yield put(getItemDataSuccess(null));
    yield put(getStaarPracticeTestSuccess(null));
    yield put(getEngineeringDesingItemsSuccess(null));
  } finally {
    localStorage.setItem("apiurl", "");
    yield put(stopSpinner());
  }
}

export function* getEngineeringDesingItems(action) {
  try {
    yield put(startSpinner());
    const fetchURL = url.content.getEngineeringDesingItems;
    const edData = yield call(doPost, fetchURL, action.payload);
    if ([401, "401"].includes(edData?.status)) {
      yield put(showPopUp(401));
      yield put(getItemDataSuccess(null));
      yield put(getStaarPracticeTestSuccess(null));
    } else yield put(getEngineeringDesingItemsSuccess(edData?.data?.data));
  } catch (error) {
    yield put(showPopUp(501));
    yield put(getStaarPracticeTestSuccess(null));
    yield put(getItemDataSuccess(null));
    yield put(getEngineeringDesingItemsSuccess(null));
    console.log(error);
  } finally {
    yield put(stopSpinner());
  }
}
export function* getSearchItemData(action) {
  try {
    yield put(startSpinner());
    console.log("payload",action.payload)
    const fetchURL = `${url.search.search}/?query=${action.payload.query}&product_id=${action.payload.product_id}&unit_id=${action.payload.unit_id}&subunit_id=${action.payload.subunit_id}&level3_id=${action.payload.level3_id}`;
    const searchItemData = yield call(doGetSearch, fetchURL);
    if ([401, "401"].includes(searchItemData?.status)) {
      yield put(showPopUp(401));
    } else yield put(getSearchItemSuccess(searchItemData?.data?.data));
  } catch (error) {
    yield put(showPopUp(501));
    console.log(error);
  } finally {
    yield put(stopSpinner());
  }
}

export function* setPreviewItems(action) {
  try {
    yield put(startSpinner());
    const fetchURL = `${url.preview.getPreviewItemAccessed}?user_puid=${action.payload}`;
    const edData = yield call(doGet, fetchURL, action.payload);
    if ([401, "401"].includes(edData?.status)) yield put(showPopUp(401));
    else yield put(setPreviewItemDataSuccess(edData?.data?.data));
  } catch (error) {
    yield put(showPopUp(501));
    yield put(setPreviewItemDataSuccess(null));
    console.log(error);
  } finally {
    yield put(stopSpinner());
  }
}

export function* callGetUnits() {
  yield takeLatest(actions.GET_UNIT_DATA, getUnits);
}

export function* callGetCorelationData() {
  yield takeLatest(actions.GET_CORELATION_DOCUMENT_DATA, getCorelationData);
}
export function* callGetItemBreakoutList() {
  yield takeLatest(actions.GET_ITEM_BREAKOUT_LIST, getItemBreakoutList);
}
export function* callGradesandStandardData() {
  yield takeLatest(actions.GET_GRADES_AND_STANDARDS, getGradesAndStandard);
}

export function* callSetDefaultFilter() {
  yield takeLatest(actions.SET_DEFAULT_FILTER, setDefaultFilter);
}

export function* callGetStaarPracticeTest() {
  yield takeLatest(actions.GET_STAAR_PRATICE_TEST, getStaarPracticeTest);
}

export function* callGetItemData() {
  yield takeLatest(actions.GET_ITEMS_DATA, getItemData);
}

export function* callEngineeringDesingItems() {
  yield takeLatest(
    actions.GET_ENGINEERING_DESIGN_ITEM,
    getEngineeringDesingItems
  );
}

export function* callSetPreviewItems() {
  yield takeLatest(actions.SET_PREVIEW_ITEM_DATA, setPreviewItems);
}

export function* callGetSearchItem() {
  yield takeLatest(actions.GET_SEARCH_ITEM, getSearchItemData);
}

export function* contentLibrarySaga() {
  yield all([
    callGetUnits(),
    callSetDefaultFilter(),
    callGetStaarPracticeTest(),
    callGetItemData(),
    callGetCorelationData(),
    callGradesandStandardData(),
    callGetItemBreakoutList(),
    callEngineeringDesingItems(),
    callGetSearchItem(),
    callSetPreviewItems(),
  ]);
}
